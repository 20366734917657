import React, { Component } from "react";
import { Image, Container, Row, Col, Carousel } from "react-bootstrap";
import FirstImage from "../../img/contest.jpg";
import FinalenImage from "../../img/cheeret2020.jpg";
import RockenImage from "../../img/rocken.jpg";
// import KappflygningenImage from '../../img/kappflygningen2020.jpg';
import KappseglingenImage from "../../img/seglingen.jpg";
import TivolitImage from "../../img/tivolit.jpg";
import ImageCard from "../ImageCard";
import Result2022 from "../../img/2022points.png";
import Result2021 from "../../img/2021points.jpg";
import Result2020 from "../../img/2020points.png";
import Result2019 from "../../img/2019points.png";
import CameraImage from "../../img/camera.png";
import { titel, text1, text2 } from "../../style.js";

/*
TODO: add första dagen
På Mottagningens första dag samlas alla nyantagna på Götaplatsen 8:30. Där blir de välkomnade av kommittéer och föreningar på sina respektive sektioner. Efter lite aktiviteter tågar alla upp till Teknologgården där det hålls tal av rektor, kårordförande och MKs ordförande. Efter detta håller vi i MK presentationer i RunAn i sex olika grupper under resten av dagen medans respektive sektions mottagningskommitté arrangerar egna aktiviteter för de nyantagna på sektionen.
*/

class ReceptionContest extends Component {
  render() {
    let width = window.innerWidth;

    if (width < 600) {
      return (
        <Container>
          {/* Row 1 */}
          <Row lg={2} md={1} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <h1 style={titel}>ARRANGEMANG</h1>
            </Col>
            <Col>
              <img src={FirstImage} style={imgStyle1} alt={""}></img>
            </Col>
            <Col>
              <div style={text2}>
                Mottagningskampen är en tävling mellan teknologsektionerna på
                Chalmers som pågår under hela mottagningen. I varje
                tävlingsmoment tävlar ett lag per sektion som representanter för
                alla nya studenter. Tävlingen är indelad i fyra grenar:
                Kappseglingen, Rocken, Cheeret och Finalen.
                <br></br>
                <br></br>
                Utöver Mottagningskampen arrangerar även vi i MK de centrala
                delar under första dagen, som inkluderar Götaplatsen,
                Teknologgården och RunAn. Dessutom arrangerar vi även Tivolit.
                Det är ett arrangemang som tar plats på Teknologgården, och
                massa olika kår- och sektionsföreningar ställer upp stationer i
                olika former av tävlingar, underhållning och god mat att äta.
              </div>
            </Col>
          </Row>
          <Row style={topMargin}>
            <div style={text1}>ARRANGEMANG</div>
          </Row>
          <Row>
            <div style={divider}></div>
          </Row>
          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <ImageCard ImageSrc={TivolitImage} Title={"TIVOLIT"} />
              <div style={text2}>
                Föreningar och kommittéer på Chalmers samlas på Teknologgården
                för att tillsammans skapa ett hav av roliga aktiviteter, god mat
                att äta och massa roliga tävlingar att delta i. Här kan du prova
                på allt från att rida på en elektrisk tjur till att åka pulka
                nedför Olgas Trappor mitt i sommaren.
                <br></br>
              </div>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/tivolit/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Tivolit 2022
                  </h5>
                </Row>
              </a>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <ImageCard
                ImageSrc={KappseglingenImage}
                Title={"KAPPSEGLINGEN"}
              />
              <div style={text2}>
                Kappseglingen är ett båtrace där alla nyantagna bygger flottar
                för att sedan tävla med dem i en av Göteborgs kanaler. I början
                av mottagningen utses fyra personer från varje sektion att tävla
                i sektionens lag. Under den andra mottagningsveckan får alla
                nyantagna på sektionen hjälpa till att bygga sektionens båt på
                Kemigården. I slutet av veckan sker själva tävlingen. Alla som
                ska tävla eller titta på tågar i en parad ned till
                tävlingsplatsen. Sedan tävlar sektionerna först i deltävlingar
                för att sedan gå till finalrace där en vinnare utses.
                Kappseglingen är det första arrangemanget i Mottagningskampen.
                <br></br>
              </div>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/kappseglingen/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Kappseglingen 2022
                  </h5>
                </Row>
              </a>
            </Col>
            {/* <Col>
                            <ImageCard ImageSrc={KappflygningenImage} Title={"KAPPFLYGNINGEN"} />
                            <div style={text2}>
                                Kappflygningen är ett arrangemang som utspelar sig på Teknologgården. Denna tävling handlar om att varje sektion ska bygga den bästa och längst flygande farkosten som sedan ska kastas ut från Olgas Trappor utav ett sektionslag. Här gäller det att bygga den mest fyndiga och stiligaste farkosten, och sedan få den i mål på längsta sträckan.
                                <br></br>
                            </div>
                        </Col>
                        <Col style={cameraLogo}>
                            <a href="https://www.cffc.se/bilder/2021/kappflygningen/">
                                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                                    <Image
                                        alt="Camera Logo"
                                        height={22}
                                        src={CameraImage}
                                    />
                                    <h5 style={{ color: "black", marginLeft: "10px" }}>Kappflygningen 2021</h5>
                                </Row>
                            </a>
                        </Col> */}
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <ImageCard ImageSrc={RockenImage} Title={"ROCKEN"} />

              <div style={text2}>
                Rocken är Nollans egen konsert under Mottagningen. Genom att
                göra den grymmaste spelningen under hejarop från publiken
                försöker varje sektionslag att vinna denna gren i
                Mottagningskampen. En jury utser sedan den bästa rockande
                sektionen. Rocken är ett samarrangemang tillsammans med
                Programkommittén PU
                <br></br>
              </div>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/rocken/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Rocken 2022
                  </h5>
                </Row>
              </a>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <ImageCard ImageSrc={FinalenImage} Title={"CHEERET & FINALEN"} />

              <div style={text2}>
                Cheeret sker i samband med Finalen. På fyndiga och roliga sätt
                hejar lagen i Cheer fram segern för sitt sektionslag i Finalen
                och sin egen titel som det bästa Cheerlaget. Här gäller det att
                komma på klockrena ramsor som passar in på ens sektion och
                klädda i coola, färgglada utstyrslar. Poängen baseras på hur
                kreativ Nollan är samt hur väl Nollan får igång publiken!
                <br></br>
                Finalen sker på campus Lindholmen, där ett lag från varje
                sektion tävlar i tre spännande grenar. Finalen är det avslutande
                arrangemanget för den centrala mottagningen och avslöjas då det
                som alla spänt har väntat på - vinnaren av Mottagningskampen!
                <br></br>
              </div>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/cheeret_och_finalen/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Cheeret & Finalen 2022
                  </h5>
                </Row>
              </a>
            </Col>
          </Row>
          <Row style={{ borderBottom: "1px solid black" }}>
            <h3 style={{ marginTop: "40px" }}>RESULTAT</h3>
          </Row>
          <Row>
            <Carousel style={carStyle} interval={null} variant="dark">
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2022}
                  alt="Points 2022"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2021}
                  alt="Points 2021"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2020}
                  alt="Points 2020"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2019}
                  alt="Points 2019"
                />
              </Carousel.Item>
            </Carousel>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <h1 style={titel}>ARRANGEMANG</h1>
              <p style={text2}>
                Mottagningskampen är en tävling mellan teknologsektionerna på
                Chalmers som pågår under hela mottagningen. I varje
                tävlingsmoment tävlar ett lag per sektion som representanter för
                alla nya studenter. Tävlingen är indelad i fyra grenar:
                Kappseglingen, Rocken, Cheeret och Finalen.
              </p>
              <p style={text2}>
                Utöver Mottagningskampen arrangerar även vi i MK de centrala
                delar under första dagen, som inkluderar Götaplatsen,
                Teknologgården och RunAn. Dessutom arrangerar vi även Tivolit.
                Det är ett arrangemang som tar plats på Teknologgården, och
                massa olika kår- och sektionsföreningar ställer upp stationer i
                olika former av tävlingar, underhållning och god mat att äta.
              </p>
            </Col>
            <Col>
              <Image fluid src={FirstImage} />
            </Col>
          </Row>
          <Row style={{ borderBottom: "1px solid black" }}>
            <h3 style={text1}>ARRANGEMANG</h3>
          </Row>
          <Row lg={2} md={2} sm={1} xs={1} style={style.topMargin}>
            <Col>
              <ImageCard
                ImageSrc={TivolitImage}
                Title={"TIVOLIT"}
                Desc={
                  "Föreningar och kommittéer på Chalmers samlas på Teknologgården för att tillsammans skapa ett hav av roliga aktiviteter, god mat att äta och massa roliga tävlingar att delta i. Här kan du prova på allt från att rida på en elektrisk tjur till att åka pulka nedför Olgas Trappor mitt i sommaren."
                }
              />
            </Col>

            <Col>
              <ImageCard
                ImageSrc={KappseglingenImage}
                Title={"KAPPSEGLINGEN"}
                Desc={
                  "Kappseglingen är ett båtrace där alla nyantagna bygger flottar för att sedan tävla med dem i en av Göteborgs kanaler. I början av mottagningen utses fyra personer från varje sektion att tävla i sektionens lag. Under den andra mottagningsveckan får alla nyantagna på sektionen hjälpa till att bygga sektionens båt på Kemigården. I slutet av veckan sker själva tävlingen. Alla som ska tävla eller titta på tågar i en parad ned till tävlingsplatsen. Sedan tävlar sektionerna först i deltävlingar för att sedan gå till finalrace där en vinnare utses. Kappseglingen är det första arrangemanget i Mottagningskampen."
                }
              />
              {/* <ImageCard ImageSrc={KappflygningenImage} Title={"KAPPFLYGNINGEN"} Desc={"Kappflygningen är ett arrangemang som utspelar sig på Teknologgården. Denna tävling handlar om att varje sektion ska bygga den bästa och längst flygande farkosten som sedan ska kastas ut från Olgas Trappor utav ett sektionslag. Här gäller det att bygga den mest fyndiga och stiligaste farkosten, och sedan få den i mål på längsta sträckan."} /> */}
            </Col>
          </Row>
          <Row>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/tivolit/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Bilder från Tivolit 2022
                  </h5>
                </Row>
              </a>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/kappseglingen/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Bilder från Kappseglingen 2022
                  </h5>
                </Row>
              </a>
            </Col>
          </Row>
          <Row lg={2} md={2} sm={1} xs={1} style={style.topMargin}>
            <Col>
              <ImageCard
                ImageSrc={RockenImage}
                Title={"ROCKEN"}
                Desc={
                  "Rocken är Nollans egen konsert under Mottagningen. Genom att göra den grymmaste spelningen under hejarop från publiken försöker varje sektionslag att vinna denna gren i Mottagningskampen. En jury utser sedan den bästa rockande sektionen. Rocken är ett samarrangemang tillsammans med Programkommittén PU"
                }
              />
            </Col>
            <Col>
              <ImageCard
                ImageSrc={FinalenImage}
                Title={"CHEERET & FINALEN"}
                Desc={
                  "Cheeret sker i samband med Finalen. På fyndiga och roliga sätt hejar lagen i Cheer fram segern för sitt sektionslag i Finalen och sin egen titel som det bästa Cheerlaget. Här gäller det att komma på klockrena ramsor som passar in på ens sektion och klädda i coola, färgglada utstyrslar. Poängen baseras på hur kreativ Nollan är samt hur väl Nollan får igång publiken!  Finalen sker på campus Lindholmen, där ett lag från varje sektion tävlar i tre spännande grenar. Finalen är det avslutande arrangemanget för den centrala mottagningen och avslöjas då det som alla spänt har väntat på - vinnaren av Mottagningskampen!"
                }
              />
            </Col>
          </Row>
          <Row>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/rocken/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Bilder från Rocken 2022
                  </h5>
                </Row>
              </a>
            </Col>
            <Col style={cameraLogo}>
              <a
                href="https://cffc.se/bilder/2022/cheeret_och_finalen/"
                target="_blank"
                rel="noreferrer"
              >
                <Row style={{ marginLeft: "1px", marginTop: "15px" }}>
                  <Image alt="Camera Logo" height={22} src={CameraImage} />
                  <h5 style={{ color: "black", marginLeft: "10px" }}>
                    Bilder från Cheeret & Finalen 2022
                  </h5>
                </Row>
              </a>
            </Col>
          </Row>
          <Row style={{ borderBottom: "1px solid black" }}>
            <h3 style={{ marginTop: "40px" }}>RESULTAT</h3>
          </Row>
          <Row>
            <Carousel style={carStyle} interval={null} variant="dark">
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2022}
                  alt="Points 2022"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2021}
                  alt="Points 2021"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2020}
                  alt="Points 2020"
                />
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  rounded
                  style={imgStyle}
                  src={Result2019}
                  alt="Points 2019"
                />
              </Carousel.Item>
            </Carousel>
          </Row>
        </Container>
      );
    }
  }
}

const style = {
  topMargin: {
    marginTop: "25px",
  },
};

const imgStyle = {
  width: "100%",
  backgroundColor: "blue",
};

const divider2 = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  marginTop: "30px",
  position: "relative",
};

const carStyle = {
  marginTop: "30px",
};

const divider = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  padding: "0",
  position: "relative",
};
const topMargin = {
  marginTop: "50px",
};

const imgStyle1 = {
  position: "relative",
  top: "50%",
  transform: "translate(0, -50%)",
  width: "100%",
  borderRadius: "2%",
  marginBottom: "10px",
};

const cameraLogo = {
  left: "0",
  position: "relative",
};

export default ReceptionContest;
