import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import ML_logo from "../../img/ML_logo.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AOK from "../../img/NollK/a.jpg";
import Bof from "../../img/NollK/bof.jpg";
import AEOK from "../../img/NollK/ae.jpg";
import Dnollk from "../../img/NollK/d.jpg";
import EOK from "../../img/NollK/e.jpg";
import Fnollk from "../../img/NollK/f.jpg";
import Hnollk from "../../img/NollK/h.jpg";
import Inollk from "../../img/NollK/i.jpg";
import Nollkit from "../../img/NollK/it.jpg";
import Kok from "../../img/NollK/k.jpg";
import Mnollk from "../../img/NollK/m.jpg";
import Sjo from "../../img/NollK/sjo.jpg";
import TBK from "../../img/NollK/tbk.jpg";
import Tdnollk from "../../img/NollK/td.jpg";
import Vok from "../../img/NollK/v.jpg";
import Zok from "../../img/NollK/z.jpg";
import Gos from "../../img/NollK/gs.png";

import NollKCard from "../NollKCard";
import { titel, text1, text2, divider, flexCenter } from "../../style.js";

class NollK extends Component {
  render() {
    return (
      <Container style={paddingTop}>
        <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
          <Col>
            <div>
              <h1 style={titel}>CHALMERS MOTTAGNINGSKOMMITTÉER</h1>
              <div style={text2}>
                <p>
                  MK har hand om de centrala arrangemangen under mottagningen,
                  men utöver det så har varje sektion sin egen
                  Mottagningskommitté, NollK. Respektive sektions NollK
                  arrangerar en egen mottagningen för sektionens Nollan.
                </p>
                <p>
                  Det finns 16 mottagningskommittéer på Chalmers som tillsammans
                  med MK spenderar hela året på att förbereda för de nya Nollan
                  som anländer varje höst.
                </p>
                <p>
                  Hitta vilken mottagningskommitté som ansvarar för ditt program
                  nedan, och läs mer på respektives hemsida genom att klicka på
                  loggan.
                </p>
              </div>
            </div>
          </Col>
          <Col style={flexCenter}>
            <img
              src={ML_logo}
              style={{ width: "60%" }}
              alt={'Hjärta med texten "Mottagningen Chalmers Studentkår"'}
            ></img>
          </Col>
        </Row>
        <Row style={paddingStyle}>
          <div style={text1}>NollK</div>
        </Row>
        <Row style={divider}></Row>
        <Row
          xs={1}
          sm={1}
          md={2}
          lg={2}
          style={{ gridRowGap: "20px", marginTop: "10px" }}
        >
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={AOK}
              Title={"AØK"}
              Web={"https://www.atek.chalmers.se/sektionen/kommitteer/aok/"}
              Program={`Arkitektur 300 hp
              Arkitektur och teknik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={AEOK}
              Title={"ÆØK"}
              Web={"https://www.ae-chalmers.com/ae0k"}
              Program={`Affärsutveckling och entreprenörskap 180 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Bof}
              Title={"bØf"}
              Web={
                "https://sites.google.com/kfkb.se/kfkb-och-gs-mottagning-2022/"
              }
              Program={`Kemiteknik med fysik 300 hp
              Bioteknik 300 hp
              Globala system 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Dnollk}
              Title={"DNollK"}
              Web={"https://dnollk.se"}
              Program={`Datateknik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={EOK}
              Title={"EØK"}
              Web={"https://e0k.se"}
              Program={`Elektroteknik 300 hp
            Medicinteknik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Fnollk}
              Title={"FnollK"}
              Web={"https://ftek.se/fnollk/"}
              Program={`Teknisk fysik 300 hp
              Teknisk matematik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Gos}
              Title={"GØS"}
              Web={"http://globalasystem.se"}
              Program={`Globala System 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Hnollk}
              Title={"HØK"}
              Web={"http://www.nollk.se"}
              Program={`Datateknik 180 hp
              Elektroteknik 180 hp
              Mekatronik 180 hp
              Design och produktutveckling 180 hp
              Maskinteknik 180 hp
              Ekonomi och produktionsteknik 180 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Inollk}
              Title={"INollK"}
              Web={"https://i-chalmers.se/kommitteer"}
              Program={`Industriell ekonomi 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Kok}
              Title={"KØK"}
              Web={"http://knollk.se"}
              Program={`Kemiteknik 300 hp
            Kemiteknik 180 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Mnollk}
              Title={"MnollK"}
              Web={"http://mtek.chalmers.se/mnollk/"}
              Program={`Maskinteknik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Nollkit}
              Title={"NollKIT"}
              Web={"https://nollk.it"}
              Program={`Informationsteknik 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Sjo}
              Title={"SJØK"}
              Web={"https://www.sjonollk.se/"}
              Program={`Internationell logistik 180 hp
              Sjöbefäl 60 hp
              Sjöingenjör 180 hp
              Sjökapten 180 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={TBK}
              Title={"TBK"}
              Web={"https://tbkchalmers.se"}
              Program={`Tekniskt basår 60 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Tdnollk}
              Title={"TDnollK"}
              Web={"https://tdtek.chalmers.se/?page_id=31"}
              Program={`Teknisk design 300 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Vok}
              Title={"VØK"}
              Web={"https://www.vtek.se/foreningar-gamble/vnollk/"}
              Program={`Samhällsbyggnadsteknik 300 hp
              Samhällsbyggnadsteknik 180 hp`}
            />
          </Col>
          <Col style={colStyle}>
            <NollKCard
              ImageSrc={Zok}
              Title={"ZØK"}
              Web={"http://znollk.se"}
              Program={`Automation och mekatronik 300 hp`}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const colStyle = {};

const paddingStyle = {
  marginTop: "10px",
};

const paddingTop = {
  marginTop: "50px",
};

export default NollK;
