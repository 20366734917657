import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
// import MKlogo from "../../img/MK_logo_color.png";
import TeknologG from "../../img/technogarden.png";
import { titel, text2, flexCenter } from "../../style.js";

class Kontakt extends Component {
  render() {
    return (
      <Container>
        <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
          <Col style={{ height: "100%" }}>
            <h1 style={titel}>FÖR FÖRETAG</h1>
            <div style={text2}>
              <p style={{ fontWeight: 600 }}>
                Intresserad av att synas på mottagningen?
              </p>
              <p>
                Mottagningen pågår under fyra veckor, från och med mitten av
                augusti till mitten på september, där 3000 nya studenterna får
                en möjlighet att hitta nya kompisar, bli introducerade till
                skolan och delta i massa olika aktiviteter. Vi i MK har ett
                övergripande ansvar över Chalmers Studentkårs mottagning men kan
                även förmedla kontakten till individuella sektioner om ni vill
                nå ut till studenter från en viss sektion.
              </p>
              <p>
                Kontakta oss på{" "}
                <a href="mailto:vo@mk.chs.chalmers.se">vo@mk.chs.chalmers.se</a>
                .
              </p>
            </div>
          </Col>
          <Col style={flexCenter}>
            <img
              src={TeknologG}
              style={{ width: "100%" }}
              alt={"Teknologgården med Nollan"}
            ></img>
          </Col>
        </Row>
        <Row style={text2}>
          <Col>
            <p style={{ fontWeight: 600, marginTop: "50px" }}>
              Intresserad av att synas på Chalmers Studentkår utanför
              mottagningen?
            </p>
            <p>
              Hör isåfall av dig till våra vänner på Chalmers Studentkår
              Promotion{" "}
              <a href="https://www.chspromotion.com/">
                https://www.chspromotion.com/
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Kontakt;
